export enum PermissionsEnum {
    VISIVEL = 'visivel',
    TRANSACAO_LIBERACAO_ONLINE_VISUALIZAR = 'transacao_liberacao_online_visualizar',
    EMPRESTIMO_INCLUIR = 'emprestimo_incluir',
    ORGANIZACAO_VISUALIZAR = 'organizacao_visualizar',
    NOTIFICACAO_INCLUIR = 'notificacao_incluir',
    LOTE_SYNC_STATUS_ALTERAR = 'lote_sync_status_alterar',
    CONTA_EXCLUIR = 'conta_excluir',
    ANTECIPACAO_LOTE_VISUALIZAR = 'antecipacao_lote_visualizar',
    COBRANCA_STATUS_ALTERAR = 'cobranca_status_alterar',
    TRANSACAO_TAXA_INCLUIR = 'transacao_taxa_incluir',
    ORGANIZACAO_PLANO_ALTERAR = 'organizacao_plano_alterar',
    PLANO_ALTERAR = 'plano_alterar',
    EMPRESTIMO_EXCLUIR = 'emprestimo_excluir',
    TRANSACAO_SPLIT_ALTERAR = 'transacao_split_alterar',
    VENDEDOR_VISUALIZAR = 'vendedor_visualizar',
    SELL_SIMULATOR_CALCULATE = 'sell_simulator_calculate',
    COBRANCA_STATUS_VISUALIZAR = 'cobranca_status_visualizar',
    ORGANIZATION_TEST_INSERT_OR_UPDATE = 'organization_test_insert_or_update',
    ANTECIPACAO_LOTE_STATUS_ALTERAR = 'antecipacao_lote_status_alterar',
    BANCO_INCLUIR = 'banco_incluir',
    PAPEL_PERMISSAO_VISUALIZAR = 'papel_permissao_visualizar',
    PARTICULAR_ALTERAR_PLANO = 'particular_alterar_plano',
    ASSIGNMENT_TERM_DELETE = 'assignment_term_delete',
    CONTROLE_TERMINAL_EXCLUIR = 'controle_terminal_excluir',
    CONCILIACAO_TOTAIS = 'conciliacao_totais',
    ANTECIPACAO_DOCUMENTO_VISUALIZAR = 'antecipacao_documento_visualizar',
    ANTECIPACAO_LOTE_STATUS_VISUALIZAR = 'antecipacao_lote_status_visualizar',
    LOTE_EXCLUIR = 'lote_excluir',
    GUARANTOR_INCLUDE = 'guarantor_include',
    VENDEDOR_EXPORTAR = 'vendedor_exportar',
    USUARIO_EXPORTAR = 'usuario_exportar',
    TRANSACAO_LIBERACAO_ONLINE_INCLUIR = 'transacao_liberacao_online_incluir',
    ORGANIZACAO_EXPORTAR = 'organizacao_exportar',
    EVENTO_NOTIFICACAO_ALTERAR = 'evento_notificacao_alterar',
    ANTECIPACAO_LOTE_STATUS_INCLUIR = 'antecipacao_lote_status_incluir',
    MEDICO_PLANO_SAUDE_SENHA_EXCLUIR = 'medico_plano_saude_senha_excluir',
    VENDEDOR_SINCRONIZAR_STATUS = 'vendedor_sincronizar_status',
    ESPECIALIDADE_ALTERAR = 'especialidade_alterar',
    EMPRESTIMO_ALTERAR = 'emprestimo_alterar',
    COBRANCA_INCLUIR = 'cobranca_incluir',
    FERIADO_EXCLUIR = 'feriado_excluir',
    ANTECIPACAO_LOTE_ALTERAR = 'antecipacao_lote_alterar',
    PLANO_INCLUIR = 'plano_incluir',
    TERMINAL_PARERAR = 'terminal_parerar',
    PERMISSAO_EXCLUIR = 'permissao_excluir',
    REPORTS_RETROSPECTIVE_VISUALIZE = 'reports_retrospective_visualize',
    MEDICO_PLANO_SAUDE_LOGIN_VISUALIZAR = 'medico_plano_saude_login_visualizar',
    BANCO_ALTERAR = 'banco_alterar',
    LOTE_SYNC_STATUS_DELETAR = 'lote_sync_status_deletar',
    ANTECIPACAO_LOTE_INCLUIR = 'antecipacao_lote_incluir',
    ANTECIPACAO_DOCUMENTO_INCLUIR = 'antecipacao_documento_incluir',
    VENDEDOR_INCLUIR = 'vendedor_incluir',
    GUARANTOR_VISUALIZE_ALL = 'guarantor_visualize_all',
    USUARIO_ALTERAR_COLUNA_NOTIFICACAO = 'usuario_alterar_coluna_notificacao',
    ORGANIZACAO_PLANO_INCLUIR = 'organizacao_plano_incluir',
    ANTECIPACAO_STATUS_VISUALIZAR = 'antecipacao_status_visualizar',
    VENDEDOR_ALTERAR = 'vendedor_alterar',
    CONTA_VINCULADA_ALTERAR = 'conta_vinculada_alterar',
    TRANSACAO_ANTECIPACAO_STATUS_INCLUIR = 'transacao_antecipacao_status_incluir',
    NOTIFICACAO_ALTERAR = 'notificacao_alterar',
    USUARIO_ALTERAR = 'usuario_alterar',
    VENDEDOR_ALTERAR_STATUS_PARA_SOB_SUSPEITA = 'vendedor_alterar_status_para_sob_suspeita',
    CONTROLE_TERMINAL_VISUALIZAR = 'controle_terminal_visualizar',
    PAPEL_PERMISSAO_INCLUIR = 'papel_permissao_incluir',
    VENDEDOR_MOTIVO_SOB_SUSPEITA_INCLUDE = 'vendedor_motivo_sob_suspeita_include',
    DOCUMENTO_EXCLUIR = 'documento_excluir',
    COBRANCA_ASSINATURA_VISUALIZAR = 'cobranca_assinatura_visualizar',
    TIPO_CLIENTE_EXCLUIR = 'tipo_cliente_excluir',
    ANTECIPACAO_DOCUMENTO_ALTERAR = 'antecipacao_documento_alterar',
    RECEIPT_VISUALIZE = 'receipt_visualize',
    MEDICO_PLANO_SAUDE_EXPORTAR = 'medico_plano_saude_exportar',
    TRANSACAO_ANTECIPACAO_STATUS_ALTERAR = 'transacao_antecipacao_status_alterar',
    DOCUMENTO_INCLUIR = 'documento_incluir',
    BANCO_EXCLUIR = 'banco_excluir',
    TRANSACAO_ANTECIPACAO_ALTERAR = 'transacao_antecipacao_alterar',
    USUARIO_VISUALIZAR = 'usuario_visualizar',
    LOGIN_HISTORICO_VISUALIZAR = 'login_historico_visualizar',
    LOGIN_LOG_INCLUIR = 'login_log_incluir',
    LOGIN_HISTORICO_ALTERAR = 'login_historico_alterar',
    PAPEL_VISUALIZAR = 'papel_visualizar',
    FERIADO_INCLUIR = 'feriado_incluir',
    BANNER_UPDATE = 'banner_update',
    TRANSACAO_CARTAO_ALTERAR = 'transacao_cartao_alterar',
    CONTA_VISUALIZAR = 'conta_visualizar',
    FIDC_EXCLUIR = 'fidc_excluir',
    LOGIN_HISTORICO_EXCLUIR = 'login_historico_excluir',
    TRANSACAO_RECEBIVEL_INCLUIR = 'transacao_recebivel_incluir',
    MEDICO_PLANO_SAUDE_INCLUIR = 'medico_plano_saude_incluir',
    CONTROLE_TERMINAL_RELATORIO = 'controle_terminal_relatorio',
    CONFIGURACAO_EMPRESTIMO_ALTERAR = 'configuracao_emprestimo_alterar',
    TRANSACTION_PAYMENT = 'transaction_payment',
    TRANSACAO_ANTECIPACAO_EXCLUIR = 'transacao_antecipacao_excluir',
    MEDICO_PLANO_SAUDE_SENHA_VISUALIZAR = 'medico_plano_saude_senha_visualizar',
    PLANO_SAUDE_ALTERAR = 'plano_saude_alterar',
    MEDICO_PLANO_SAUDE_LOGIN_EXCLUIR = 'medico_plano_saude_login_excluir',
    LOTE_INCLUIR = 'lote_incluir',
    ORGANIZACAO_ASSINATURA_PLANO_ALTERAR_CAMPO_INADIMPLENTE = 'organizacao_assinatura_plano_alterar_campo_inadimplente',
    CONTROLE_TERMINAL_ALTERAR = 'controle_terminal_alterar',
    BANNER_VISUALIZE = 'banner_visualize',
    SPLIT_PAGAMENTO = 'split_pagamento',
    USUARIO_VISUALIZAR_OUTRO_USUARIO = 'usuario_visualizar_outro_usuario',
    ORGANIZACAO_ASSINATURA_PLANO_EXCLUIR = 'organizacao_assinatura_plano_excluir',
    EMPRESTIMO_VISUALIZAR = 'emprestimo_visualizar',
    VENDEDOR_ALTERAR_ESPECIALIDADE = 'vendedor_alterar_especialidade',
    RELATORIO_GERAL_VISUALIZAR = 'relatorio_geral_visualizar',
    PLANO_SAUDE_INCLUIR = 'plano_saude_incluir',
    TRANSACAO_LIBERACAO_ONLINE_EXCLUIR = 'transacao_liberacao_online_excluir',
    ORGANIZACAO_ASSINATURA_PLANO_ALTERAR = 'organizacao_assinatura_plano_alterar',
    LOGIN_LOG_ALTERAR = 'login_log_alterar',
    USUARIO_ALTERAR_OUTRO_USUARIO = 'usuario_alterar_outro_usuario',
    MEDICO_PLANO_SAUDE_INCLUIR_COLUNA_ANTECIPAR_AUTOMATICAMENTE = 'medico_plano_saude_incluir_coluna_antecipar_automaticamente',
    ASSIGNMENT_TERM_VISUALIZE = 'assignment_term_visualize',
    EVENTO_NOTIFICACAO_INCLUIR = 'evento_notificacao_incluir',
    COBRANCA_ASSINATURA_INCLUIR = 'cobranca_assinatura_incluir',
    TRANSACAO_RECEBIVEL_ALTERAR = 'transacao_recebivel_alterar',
    PERMISSAO_VISUALIZAR = 'permissao_visualizar',
    FIDC_INCLUIR = 'fidc_incluir',
    USUARIO_INCLUIR = 'usuario_incluir',
    VENDEDOR_VISUALIZAR_TODOS = 'vendedor_visualizar_todos',
    TIPO_CLIENTE_ALTERAR = 'tipo_cliente_alterar',
    LOGS_VISUALIZAR = 'logs_visualizar',
    TRANSACAO_RECEBIVEL_VISUALIZAR = 'transacao_recebivel_visualizar',
    AUTH_WITHOUT_PASSWORD = 'auth_without_password',
    CONTA_VINCULADA_VISUALIZAR = 'conta_vinculada_visualizar',
    MEDICO_PLANO_SAUDE_EXCLUIR = 'medico_plano_saude_excluir',
    FERIADO_VISUALIZAR = 'feriado_visualizar',
    CONTA_VINCULADA_EXCLUIR = 'conta_vinculada_excluir',
    TRANSACAO_CARTAO_EXCLUIR = 'transacao_cartao_excluir',
    CONFIGURACAO_EMPRESTIMO_EXCLUIR = 'configuracao_emprestimo_excluir',
    ANTECIPACAO_STATUS_INCLUIR = 'antecipacao_status_incluir',
    CONFIGURACAO_EMPRESTIMO_INCLUIR = 'configuracao_emprestimo_incluir',
    COBRANCA_MODALIDADE_VISUALIZAR = 'cobranca_modalidade_visualizar',
    TRANSACAO_TAXA_EXCLUIR = 'transacao_taxa_excluir',
    TRANSACAO_ANTECIPACAO_STATUS_EXCLUIR = 'transacao_antecipacao_status_excluir',
    ORGANIZACAO_PLANO_EXCLUIR = 'organizacao_plano_excluir',
    PIX_TRANSACTION_CREATE = 'pix_transaction_create',
    ORGANIZATION_ENABLE_TRANSACTION_ANTICIPATION = 'organization_enable_transaction_anticipation',
    COBRANCA_MODALIDADE_TIPO_EXCLUIR = 'cobranca_modalidade_tipo_excluir',
    ANTECIPACAO_ANTECIPAR = 'antecipacao_antecipar',
    LOTE_SYNC_STATUS_INCLUIR = 'lote_sync_status_incluir',
    MEDICO_PLANO_SAUDE_SENHA_INCLUIR = 'medico_plano_saude_senha_incluir',
    TRANSACAO_ANTECIPACAO_VISUALIZAR = 'transacao_antecipacao_visualizar',
    ORGANIZACAO_ASSINATURA_PLANO_VISUALIZAR = 'organizacao_assinatura_plano_visualizar',
    MEDICO_PLANO_SAUDE_VISUALIZAR = 'medico_plano_saude_visualizar',
    GUARANTOR_DELETE = 'guarantor_delete',
    REPORTS_TRANSACTION_APPROVED_VISUALIZE = 'reports_transaction_approved_visualize',
    ORGANIZACAO_EXCLUIR = 'organizacao_excluir',
    NOTIFICACAO_EXCLUIR = 'notificacao_excluir',
    VENDEDOR_MOTIVO_SOB_SUSPEITA_VISUALIZE = 'vendedor_motivo_sob_suspeita_visualize',
    FERIADO_ALTERAR = 'feriado_alterar',
    PAPEL_PERMISSAO_EXCLUIR = 'papel_permissao_excluir',
    REPORTS_SELLERS_ACTIVE_VISUALIZE = 'reports_sellers_active_visualize',
    ESPECIALIDADE_EXCLUIR = 'especialidade_excluir',
    ESTATISTICA_INCLUIR = 'estatistica_incluir',
    ZOOP_SYNC_TRANSACTION = 'zoop_sync_transaction',
    PAPEL_INCLUIR = 'papel_incluir',
    USUARIO_PAPEL_EXCLUIR = 'usuario_papel_excluir',
    USER_CHANGE_PASSWORD = 'user_change_password',
    COBRANCA_MODALIDADE_EXCLUIR = 'cobranca_modalidade_excluir',
    ESPECIALIDADE_VISUALIZAR = 'especialidade_visualizar',
    COBRANCA_MODALIDADE_TIPO_VISUALIZAR = 'cobranca_modalidade_tipo_visualizar',
    COBRANCA_ASSINATURA_EXCLUIR = 'cobranca_assinatura_excluir',
    TRANSACAO_ALTERAR = 'transacao_alterar',
    TRANSACTION_UNDER_SUSPECT_INSERT_OR_UPDATE = 'transaction_under_suspect_insert_or_update',
    ESPECIALIDADE_INCLUIR = 'especialidade_incluir',
    CONFIGURACAO_EMPRESTIMO_VISUALIZAR = 'configuracao_emprestimo_visualizar',
    ESTATISTICA_EXCLUIR = 'estatistica_excluir',
    ANTECIPACAO_STATUS_ALTERAR = 'antecipacao_status_alterar',
    REPORTS_TRANSACTION_WITHOUT_RECEIVABLES_VISUALIZE = 'reports_transaction_without_receivables_visualize',
    PLANO_SAUDE_EXCLUIR = 'plano_saude_excluir',
    COBRANCA_MODALIDADE_TIPO_INCLUIR = 'cobranca_modalidade_tipo_incluir',
    LOTE_VISUALIZAR = 'lote_visualizar',
    TRANSACAO_INCLUIR = 'transacao_incluir',
    ANTECIPACAO_STATUS_EXCLUIR = 'antecipacao_status_excluir',
    FIDC_ALTERAR = 'fidc_alterar',
    COBRANCA_ASSINATURA_ALTERAR = 'cobranca_assinatura_alterar',
    ANTECIPACAO_INCLUIR = 'antecipacao_incluir',
    MEDICO_PLANO_SAUDE_ALTERAR_COLUNA_ANTECIPAR_AUTOMATICAMENTE = 'medico_plano_saude_alterar_coluna_antecipar_automaticamente',
    NOTIFICACAO_VISUALIZAR = 'notificacao_visualizar',
    LOGIN_HISTORICO_INCLUIR = 'login_historico_incluir',
    DASHBOARD = 'dashboard',
    PAYMENT_LINK_STORE = 'payment_link_store',
    VENDEDOR_MOTIVO_SOB_SUSPEITA_VISUALIZE_ALL = 'vendedor_motivo_sob_suspeita_visualize_all',
    TRANSACAO_LIBERACAO_ONLINE_ALTERAR = 'transacao_liberacao_online_alterar',
    CONTA_INCLUIR = 'conta_incluir',
    TRANSACAO_SPLIT_INCLUIR = 'transacao_split_incluir',
    VENDEDOR_EXCLUIR = 'vendedor_excluir',
    LOTE_ALTERAR = 'lote_alterar',
    BANNER_STORE = 'banner_store',
    MEDICO_PLANO_SAUDE_ALTERAR = 'medico_plano_saude_alterar',
    TRANSACAO_ANTECIPACAO_INCLUIR = 'transacao_antecipacao_incluir',
    ORGANIZACAO_ALTERAR_ADMIN = 'organizacao_alterar_admin',
    SELLER_CHANGE_SPECIALTY_PLAN = 'seller_change_specialty_plan',
    LINK_CARD_TO_BUYER = 'link_card_to_buyer',
    TIPO_CLIENTE_VISUALIZAR = 'tipo_cliente_visualizar',
    ORGANIZACAO_ALTERAR = 'organizacao_alterar',
    PERMISSAO_ALTERAR = 'permissao_alterar',
    PAYMENT_LINK_VISUALIZE = 'payment_link_visualize',
    ANTECIPACAO_ANTECIPAR_EM_OUTRA_DATA = 'antecipacao_antecipar_em_outra_data',
    USUARIO_INCLUIR_COLUNA_NOTIFICACAO = 'usuario_incluir_coluna_notificacao',
    FATURA_VISUALIZAR = 'fatura_visualizar',
    FIDC_VISUALIZAR = 'fidc_visualizar',
    CARD_TOKEN_GENERATE = 'card_token_generate',
    TRANSACAO_CARTAO_INCLUIR = 'transacao_cartao_incluir',
    TRANSACTIONS_GET_ALL = 'transactions_get_all',
    CONTA_VINCULADA_INCLUIR = 'conta_vinculada_incluir',
    ASSIGNMENT_TERM_UPDATE = 'assignment_term_update',
    GUARANTOR_VISUALIZE = 'guarantor_visualize',
    USUARIO_PAPEL_INCLUIR = 'usuario_papel_incluir',
    NOTIFICATION_WEB_SEND = 'notification_web_send',
    COBRANCA_MODALIDADE_INCLUIR = 'cobranca_modalidade_incluir',
    PAPEL_ALTERAR = 'papel_alterar',
    ANTECIPACAO_LOTE_STATUS_EXCLUIR = 'antecipacao_lote_status_excluir',
    VENDEDOR_MOTIVO_SOB_SUSPEITA_DELETE = 'vendedor_motivo_sob_suspeita_delete',
    PLANO_SAUDE_VISUALIZAR = 'plano_saude_visualizar',
    ATRELAR_FIDC_AO_USUARIO = 'atrelar_fidc_ao_usuario',
    MEDICO_PLANO_SAUDE_LOGIN_ALTERAR = 'medico_plano_saude_login_alterar',
    TIPO_CLIENTE_INCLUIR = 'tipo_cliente_incluir',
    VENDEDOR_MOTIVO_SOB_SUSPEITA_UPDATE = 'vendedor_motivo_sob_suspeita_update',
    PAPEL_EXCLUIR = 'papel_excluir',
    PERMISSAO_INCLUIR = 'permissao_incluir',
    DOCUMENTO_ALTERAR = 'documento_alterar',
    COBRANCA_ALTERAR = 'cobranca_alterar',
    EVENTO_NOTIFICACAO_VISUALIZAR = 'evento_notificacao_visualizar',
    ORGANIZACAO_USUARIO_ALTERAR = 'organizacao_usuario_alterar',
    TRANSACAO_EXCLUIR = 'transacao_excluir',
    TRANSACAO_RECEBIVEL_EXCLUIR = 'transacao_recebivel_excluir',
    TRANSACAO_ANTECIPACAO_STATUS_VISUALIZAR = 'transacao_antecipacao_status_visualizar',
    ANTECIPACAO_LOTE_EXCLUIR = 'antecipacao_lote_excluir',
    ORGANIZACAO_ASSINATURA_PLANO_INCLUIR = 'organizacao_assinatura_plano_incluir',
    ORGANIZACAO_ALTERARAR_COLUNA_REFERRAL = 'organizacao_alterarar_coluna_referral',
    USUARIO_EXCLUIR = 'usuario_excluir',
    LOGIN_LOG_VISUALIZAR = 'login_log_visualizar',
    ANTECIPACAO_VISUALIZAR = 'antecipacao_visualizar',
    DOCUMENTO_VISUALIZAR = 'documento_visualizar',
    CONTROLE_TERMINAL_INCLUIR = 'controle_terminal_incluir',
    COBRANCA_VISUALIZAR = 'cobranca_visualizar',
    PLANO_EXCLUIR = 'plano_excluir',
    MEDICO_PLANO_SAUDE_SENHA_ALTERAR = 'medico_plano_saude_senha_alterar',
    EVENTO_NOTIFICACAO_EXCLUIR = 'evento_notificacao_excluir',
    COBRANCA_EXCLUIR = 'cobranca_excluir',
    VISUALIZAR_PAPEL_USUARIO = 'visualizar_papel_usuario',
    LOGIN_LOG_EXCLUIR = 'login_log_excluir',
    TRANSACAO_TAXA_ALTERAR = 'transacao_taxa_alterar',
    COBRANCA_STATUS_INCLUIR = 'cobranca_status_incluir',
    TRANSACAO_CARTAO_VISUALIZAR = 'transacao_cartao_visualizar',
    TRANSACAO_VISUALIZAR = 'transacao_visualizar',
    STORE_ORGANIZATION_ADMIN = 'store_organization_admin',
    ANTECIPACAO_ALTERAR = 'antecipacao_alterar',
    ORGANIZACAO_PLANO_VISUALIZAR = 'organizacao_plano_visualizar',
    TRANSACAO_SPLIT_VISUALIZAR = 'transacao_split_visualizar',
    LOGIN_ON_ADMIN_DASHBOARD = 'login_on_admin_dashboard',
    ANTECIPACAO_EXCLUIR = 'antecipacao_excluir',
    ANTECIPACAO_DOCUMENTO_EXCLUIR = 'antecipacao_documento_excluir',
    ESTATISTICA_VISUALIZAR = 'estatistica_visualizar',
    ESTATISTICA_ALTERAR = 'estatistica_alterar',
    CONTA_ALTERAR = 'conta_alterar',
    TRANSACTION_SYNC_STATUS = 'transaction_sync_status',
    DASHBOARD_FINANCEIRO_VISUALIZAR = 'dashboard_financeiro_visualizar',
    COBRANCA_MODALIDADE_TIPO_ALTERAR = 'cobranca_modalidade_tipo_alterar',
    ASSIGNMENT_TERM_STORE = 'assignment_term_store',
    COBRANCA_MODALIDADE_ALTERAR = 'cobranca_modalidade_alterar',
    TRANSACAO_TAXA_VISUALIZAR = 'transacao_taxa_visualizar',
    LOTE_SYNC_STATUS_VISUALIZAR = 'lote_sync_status_visualizar',
    COBRANCA_STATUS_EXCLUIR = 'cobranca_status_excluir',
    GUARANTOR_UPDATE = 'guarantor_update',
    SKIP_TENANT = 'skip_tenant',
    ULTIMAS_ATIVIDADES_VISUALIZAR = 'ultimas_atividades_visualizar',
    PLAN_SELLER_PRO_FIRST_TIME = 'plan_seller_pro_first_time',
    SELLER_CHANGE_ORGANIZATION = 'seller_change_organization',
    TRANSACAO_SPLIT_EXCLUIR = 'transacao_split_excluir',
    PLANO_VISUALIZAR = 'plano_visualizar',
    BANNER_DELETE = 'banner_delete',
    MEDICO_PLANO_SAUDE_LOGIN_INCLUIR = 'medico_plano_saude_login_incluir',
    VENDEDOR_GATEWAY_VISUALIZAR = 'vendedor_gateway_visualizar'
}