export class APIRoutes{
    public static readonly OAUTH = 'oauth/token';
    public static readonly SELF_USER = 'user/self';  
    public static readonly ADMIN = 'user/admin';  
    public static readonly USER_LIST = 'user';  
    public static readonly EMPRESTIMO = 'emprestimo';  
    public static readonly ORGANIZACAO = 'organizacao';  
    public static readonly FORGOT_PASSWORD = 'password/email';  
    public static readonly RESET_PASSWORD = 'password/reset';  
    public static readonly CHANGE_PASSWORD = 'user/change/password';
    public static readonly USER_REGISTER = 'organizacao';  
    public static readonly UNIDADE = 'unidade';  
    public static readonly UNIDADE_ENDERECO = 'unidade_endereco';  
    public static readonly UNIDADE_CONTATO = 'unidade_contato';
    public static readonly CONTA = 'conta';  
    public static readonly ROLLOUT = 'rollout';  
    public static readonly BANCO = 'banco';  
    public static readonly LOTE = 'lote';  
    public static readonly MEDICO = 'medico';  
    public static readonly MEDICO_INFO = 'medico_informacao_profissional';  
    public static readonly CONSELHO_PROFISSIONAL = 'conselho_profissional';  
    public static readonly CONTATO_MEDICO = 'medico_contato';
    public static readonly PLANO_SAUDE = 'plano_saude';
    public static readonly PLANO_SAUDE_LOGIN = 'plano_saude_login';
    public static readonly MEDICO_PLANO = 'medico_plano_saude';
    public static readonly ESPECIALIDADE_MEDICA = 'especialidade_medica';
    public static readonly MEDICO_PLANO_ESPECIALIDADE = 'medico_plano_saude_especialidade_medica';
    public static readonly MEDICO_PLANO_DIA_ENVIO = 'medico_plano_saude_dia_envio';
    public static readonly ANTECIPACAO = 'emprestimo'
    public static readonly ANTECIPACAO_FATURA = 'emprestimo/showAntecipacaoFatura'
    public static readonly ANTECIPACAO_EMPRESTAR = 'emprestimo/emprestar'
    public static readonly ANTECIPACAO_TOTAL = 'emprestimo/showAntecipacaoTotal'
    public static readonly ANTECIPACAO_TOTAL_EMPRESTAR = 'emprestimo/emprestarTotal'
    public static readonly OPORTUNIDADE = 'dashboard/oportunidade'
    public static readonly RECEBIMENTO = 'dashboard/recebimento'
    public static readonly GLOSAS = 'dashboard/glosa_mensal'
    public static readonly PRODUCAO = 'dashboard/producao_recebimento'
    public static readonly FATURAMENTO = 'dashboard/faturamento_por_plano'
    public static readonly TOTAL_ANTECIPADO_PLANO = 'dashboard/total_antecipado_por_plano'
    public static readonly ATENDIMENTO_PLANO = 'dashboard/atendimento_por_plano'
    public static readonly RESUMO_MENSAL = 'dashboard/resumo_mensal'
    public static readonly MEDICO_PLANO_SAUDE_CONTRATO = 'medico_plano_saude_contrato'
    public static readonly UPLOAD = 'upload'
    public static readonly VENDEDOR_STATUS = 'vendedor_status'
    public static readonly GATEWAY = 'gateway'
}